$linkColor: hsl(220, 100%, 30%);
$textColor: hsl(220, 0%, 20%);
$RadGreen: #35D461;
$RadYellow: #F9E104;
$RadOrange: #F99D07;
$RadPurple: #882FF6;
$RadBlue: #37B6F6;
$RadBlueLight: #89eeff;
$RadRedLight: #ff5378;
$RadRed: #ea1f4b;

.hGreen, .hYellow, .hOrange, .hPurple, .hBlue, .hRed {
	color: black;
	padding: 10px;
	// display: inline-block;
	margin: 10px 0px;
	// background-image: linear-gradient(90deg, rgba(255,255,255,0) 90%, rgba(255,255,255,1) 100%);
}

.hGreen {
	background-color: $RadGreen;
	// background: linear-gradient(90deg, $RadGreen 90%, change-color($RadGreen, $alpha: .33) 100%);
}
.hYellow {
	background-color: $RadYellow;
	// background: linear-gradient(90deg, $RadYellow 90%, change-color($RadYellow, $alpha: .33) 100%);
}
.hOrange {
	background-color: $RadOrange;
	// background: linear-gradient(90deg, $RadOrange 90%, change-color($RadOrange, $alpha: .33) 100%);
}
.hPurple {
	background-color: $RadPurple;
	// background: linear-gradient(90deg, $RadPurple 90%, change-color($RadPurple, $alpha: .33) 100%);
	color: white;
}
.hBlue {
	background-color: $RadBlue;
	// background: linear-gradient(90deg, $RadBlue 90%, change-color($RadBlue, $alpha: .33) 100%);
}
.hRed {
	background-color: $RadRedLight;
	// background: linear-gradient(90deg, $RadRedLight 90%, change-color($RadRedLight, $alpha: .33) 100%);
}

@mixin graphPaper {
	background: #f5f6f6;
	background-image: url('./images/graph2.png');
	background-size: 100px;
	background-attachment: local;
}

* {
	box-sizing: border-box;
}

body {
	@include graphPaper;
	color: $textColor;
	font-size: 18px;
	font-weight: 400;
	// font-family: 'Didact Gothic', sans-serif;
	// font-family: 'Roboto', sans-serif;
	font-family: 'Source Sans Pro', sans-serif;
	/* font-family: sans-serif; */
	// font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	// font-weight: 200;
	padding: 0;
	margin: 0;
}
h1 {
	color: $textColor;
	// editable title (for projects)
	input {
		font-size: inherit;
		font-family: inherit;
		width: inherit;
		padding-left: 10px;
	}
}

h1, h2, h3 {
	font-family: 'Rubik';
}

h2.bigger {
	font-size: 1.8em;
}
// h1, h2, h3, h4, h5, h6 {
// 	font-family: 'lsv', sans-serif;
// 	font-variation-settings: 'wght' 750, 'wdth' 100;
// }

// links shouldn't need a href to be styled as links.  override visited color;
a {
	text-decoration: none;
	color: $linkColor;
	p & {
		font-weight: bold;
	}

	&:hover, &:active, &:focus {
		text-decoration: underline;
	}
}

.navLink {
	margin-left: 15px;
	@media (max-width: 899px) {
		display: none;
	}
}

button {
	// not sure why this isn't the default
	cursor: pointer;
}

input.input {
	font-size: .9em;
	padding: 5px;
	border-radius: 5px;
}

blockquote {
	background: $RadBlueLight;
	// background: #37B6F6;
	padding: 10px;
	margin-left: 20px;
	margin-right: 10px;
}

hr.spacer {
	color: transparent;
	height: 40px;
	border: none;

	&.robot {
		background-image: url(https://robohash.org/simon?size=150x150);
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		margin: -5px 0px;
		height: 75px;
	}
}

.container {
	box-sizing: content-box;
	max-width: 1100px;
	margin: 0 auto;
	padding: 15px 20px;
	line-height: 1.5em;

	&.solid {
		background: white;
	}
	&.narrow {
		background: white;
		max-width: 720px;
	}

	&.full {
		max-width: unset;
	}

	$lightness: 85%;
	.blGreen {
		background-color: change-color($color: $RadGreen, $lightness: $lightness);
	}
	.blYellow {
		background-color: change-color($color: $RadYellow, $lightness: $lightness);
	}
	.blOrange {
		background-color: change-color($color: $RadOrange, $lightness: $lightness);
	}
	.blPurple {
		background-color: change-color($color: $RadPurple, $lightness: $lightness);
	}
	.blBlue {
		background-color: change-color($color: $RadBlue, $lightness: $lightness);
	}
	.blRed {
		background-color: change-color($color: $RadRedLight, $lightness: $lightness);
	}
	.blWhite {
		background-color: white;
	}

	& + .container {
		margin-top: 30px;
	}

	p:first-child {
		margin-top: 0px;
	}

	p:last-child {
		margin-bottom: 0px;
	}
}

// .scrollContainer {
// 	overflow-y: auto;
// }

h1 {
	font-size: 35px;
	text-align: center;
	line-height: 48px;
	margin: 0; // 2px 10px;
}

.SplitPane {
	display: flex;
	width: 100%;
	.pane {
		flex: 1;
		width: 50%;
		overflow-y: auto;

	}
	.pane.left {
		@include graphPaper;
	}
}

.CanvasPage {
	.canvasWrapper {
		margin-bottom: 10;
		@media (max-width: 1259px) {
			margin-left: auto;
			margin-right: auto;
			max-width: 400px;
		}
		@media (min-width: 1260px) {
			float: left;
			margin-right: 10px;
		}
	}
}

@media (min-width: 1260px) {
	.CanvasPage {
		.pane.left {
			flex: 1.3;
		}
	}
}

@media (max-width: 849px) {
	.SharedProjectPage {
		.pane.right {
			display: none;
		}
	}
}

// possible code fonts
// PT Mono (google)
// Roboto Mono (google)

// @import url('https://cdn.jsdelivr.net/npm/hack-font@3/build/web/hack.css');
// @import url('https://fonts.googleapis.com/css?family=Fira+Code|Inconsolata|PT+Mono|Roboto+Mono&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700|Rubik:700|Roboto+Mono:400&display=swap');
// non-essential fonts (Press Start 2P) are loaded via link tag from the page head.
// see https://stackoverflow.com/questions/32759272/how-to-load-css-asynchronously

code {
	font-size: .88em;
	font-family: 'Roboto Mono', Menlo, Monaco, 'Courier New', monospace;
	tab-size: 4;

	&.box {
		// padding: 10px;
		// display: block;
		white-space: pre-wrap;
		// background: white;
		overflow-x: auto;
		line-height: 1.55em;
		width: fit-content;
		position: relative;
		&.hideable {
			&:after {
				transition: 400ms top;
				position: absolute;
				background: #1e1e1e;
				top: 100%; bottom: 0; right: 0; left: 0;
				padding-top: 13px;
				color: white;
				text-align: center;
				line-height: 100%;
				content: ' ';
			}
			&.hidden:after {
				top: 0 !important;
				content: 'Click to show example';
			}
		}
	}
	// &.snippet {
		color: hsl(0, 100%, 35%);
		// background: hsla(0, 0%, 0%, 10%);
		background: #ecebeb;
		padding: 1px 3px;
	// }
	&.noColor, h1 &, h2 &, h3 &, h4 & {
		color: inherit;
		background: inherit;
		padding: 0px 2px;
	}
}

p.intro {
	font-size: 1.1em;
	padding: 15px;
}

.grayBtn {
	background: #666;
	color: white;
	padding: 10px;
	border: 0px solid #888;
	border-radius: 2.5px;
	font-size: 0.8rem;
	cursor: pointer;
	&:hover {
		background: #777;
		border-color: #999;
	}
}

.centered {
	display: block;
	width: fit-content;
	margin-left: auto; margin-right: auto;
}

ul.basicList {
	list-style: none;
	line-height: 1.7em;
	padding-inline-start: 0;
	padding-left: 0;
}

.instructor-note {
	// display: none;
	background: #ffff9f;
	font-family: 'Roboto Mono', monospace;
	white-space: pre-line;
	font-size: .9em;
	padding: 10px;

}

.TextArea {
	background: white !important;
}

.lessonTable {
	background: #aaa;
	width: 100%;
	& th, & td {
		background: #fff;
		padding: 2px 2px 2px 4px;
	}
}

.CodeRunner {
	margin: 15px 0px;

	.editor {
		width: 100%;
		height: 100%;
	}
	.console-wrap {
		// height: 200px;
		// flex: 1 1 200px;
		// min-height: 0;
		position: relative;
	}
	.console {
		font-family: 'Roboto Mono', 'menlo', 'lucida console', monospace;
		// font-family: 'menlo', 'lucida console', 'Courier New', Courier, monospace;
		font-size: 15px;
		line-height: normal;
		white-space: pre-wrap;
		background: #333;
		color: white;
		padding: 10px;
		// min-height: 100px;
		// max-height: 300px;
		/*
		*/
		// height: 100%;
		height: 150px;
		resize: vertical;
		overflow-y: auto;
	}
	.clear {
		position: absolute;
		bottom: 10px;
		right: 10px;
	}
	.log-entry .text.log {
		color: #fff;
	}
	.log-entry .text.info {
		color: #9b9b9b;
	}
	.log-entry .text.debug {
		color: rgb(190, 188, 162);
	}
	.log-entry .text.warn {
		color: rgb(253, 255, 151);
	}
	.log-entry .text.error {
		color: rgb(255, 106, 106);
	}
	.log-entry .ts {
		float: right;
	}

	.ed-wrapper {
		flex: 4 2 600px;
		position: relative;
		min-height: 0;
		border-top: 3px solid #1d1d1d;
		background: #1d1d1d;
	}
	.button-wrapper {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}
	.outer {
		display: flex;
		flex-direction: column;
		height: 100vh;
		width: 100vw;
	}
	button {
		background: #666;
		color: white;
		padding: 10px;
		border: 0px solid #888;
		border-radius: 2.5px;
		font-size: 0.8rem;
		cursor: pointer;
	}
	button:hover {
		background: #777;
		border-color: #999;
	}

	.pane > &, .CanvasRunner & {
		margin: 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		flex: 1 1 100%;
	}
}

.Vocab {
	background: #ea1f4b;
    color: white;
    padding: 0px 10px 6px;
    float: right;
    width: 50%;
    margin: 7px 0px 7px 7px;

	h3 {
		font-size: 28px;
		margin: 15px 0px;
	}

	.definition {
		font-size: 16px;
	}
}

.contentButton {
	font-size: 23px;
	padding: 8px 10px;
	margin: 2px;
	border: 1px solid #444;
	border-radius: 4px;
	// background-color: white;
	filter: brightness(.95);
	cursor: pointer;
	&:hover, &:active, &:focus {
		// background-color: #ddd;
		filter: brightness(1);
	}
	// &.blGreen:hover, &.blGreen:active, &.blGreen:focus {
	// 	background-color: change-color($color: $RadGreen, $lightness: 75%);
	// }
	// &.blYellow:hover, &.blYellow:active, &.blYellow:focus {
	// 	background-color: change-color($color: $RadYellow, $lightness: 75%);
	// }
	// &.blOrange:hover, &.blOrange:active, &.blOrange:focus {
	// 	background-color: change-color($color: $RadOrange, $lightness: 75%);
	// }
	// &.blPurple:hover, &.blPurple:active, &.blPurple:focus {
	// 	background-color: change-color($color: $RadPurple, $lightness: 75%);
	// }
	// &.blBlue:hover, &.blBlue:active, &.blBlue:focus {
	// 	background-color: change-color($color: $RadBlue, $lightness: 75%);
	// }
	// &.blRed:hover, &.blRed:active, &.blRed:focus {
	// 	background-color: change-color($color: $RadRedLight, $lightness: 75%);
	// }
}

canvas {
	background: white;
	user-select: none;
	&:fullscreen {
		background: #444;
	}
}

.CanvasDemo {
	display: flex;
	justify-content: center;

	.WrappedCanvas {
		border: 1px solid #aaa;
	}
}

.CanvasRunner/*  canvas */ {
	border: 1px solid black;

	.WrappedCanvas {
		background: #d5dce3;
		// margin: auto;
	}
	&.vertical {
		.WrappedCanvas {
			display: block;
			// margin: auto;
		}
		canvas {
			display: block;
			margin: auto;
		}
		.CodeRunner {
			flex: none;
		}
	}
	.ed-wrapper iframe {
		height: 100% !important;
	}
}

.WrappedCanvas {
	.flatButton {
		background: transparent;
		border: none;
		color: #636363;
		font-family: Rubik;
		font-size: 24;
		margin: 4;
		margin-left: auto;
		&:hover {
			color: #444;
		}
	}
}

dl.paramList {
	dd, dt {
		display: inline;
	  }
	  dt::before {
		content:' ';
		display: block;
		margin-top: 10px;
	  }
	  dt {
		font-weight: 700;
	  }
	  dd {
		margin-inline-start: 10px;
	  }
}

.colorList {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;

	.color {
		flex: 1 0 300px;
		display: flex;
		justify-content: space-evenly;
	}

	.colorName { width: 165px; }
	.colorHex { width: 75px; }

	.colorBox {
		width: 60px;
		height: 24px;
		border: 1px solid black;
	}
}


table.codeTable {
	background: #d2d2d2;
	tr {
		// padding: 10px 0px;
		background: white;
		&:nth-child(even) {
			background: #e3f5ff;
		}
	}
	td {
		padding: 10px 10px;
		text-align: center;
		&.code {
			font-size: .88em;
			font-family: 'Roboto Mono', Menlo, Monaco, 'Courier New', monospace;
			text-align: left;
		}
	}
}

table.table {
	width: 100%;
	background: #d2d2d2;
	tr {
		// padding: 10px 0px;
		background: white;
		&:nth-child(even) {
			background: #e3f5ff;
		}
	}
	th {
		padding: 5px 10px;
	}
	td {
		padding: 10px 10px;
		// text-align: center;
		&.code {
			font-size: .88em;
			font-family: 'Roboto Mono', Menlo, Monaco, 'Courier New', monospace;
			// text-align: left;
		}
	}
}


.MatchingQuestion {
	.matched {
		padding: 10px;
		margin-bottom: 10px;
		border-radius: 5px;
	}
	.matched .q, .matched .ans {
		padding: 10px;
	}
	.cols {
		display: flex;
		flex-direction: row;
	}
	.question-list {
		// flex: 1 0 100px;
		margin-right: 20px;
	}
	.answer-list {
		flex: 1 0 100px;
	}
	.question, .answer {
		margin-bottom: 10px;
		padding: 10px;
		border-radius: 5px;
	}
	.question {
		background: #d4d4d4;
		border-radius: 5px;
		text-align: center;
	}
	.answer {
		border: 1px solid #ccc;
		padding: 9px;
	}
	.code {
		font-size: .88em;
		font-family: 'Roboto Mono', Menlo, Monaco, 'Courier New', monospace;
		color: hsl(0, 100%, 35%);
	}
	// .answer {}
	.matched {
		background: #bbe8ff;
	}
	.active {
		background: #40bfff;
	}
	.close {
		font-size: 24px;
		float: right;
		margin-right: 5px;
	}
	.question-list .question,
	.answer-list .answer,
	.selected-list .matched {
		cursor: pointer;
	}

	&.results {
		.question {
			display: inline-block;
		}
		svg {
			vertical-align: text-top;
		}
		.matched {
			line-height: 30px;
			&.correct {
				// background: #eeffee;
				background: #dcffe9;
			}
			&.incorrect {
				background: #ffe7f2;
				// background: #ffdddd;
			}
		}
	}
}

.Emoji {
    // height: 1.33em;
    // width: 1.33em;
    // vertical-align: -0.19em;
    // background-size: 1.33em 1.33em;
    // display: inline-block;
	// vertical-align: -0.1em;
    display: inline;
	vertical-align: 0;
    background-repeat: no-repeat;
    background-position: center center;
	// height: 1em;
	// width: 1em;
	margin: 0 .05em 0 .1em;
	color: transparent !important;
}
